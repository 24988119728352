import LANGUAGES from '@/configs/languages';

export enum Gender {
  MALE,
  FEMALE,
  BOTH,
}

export type Language = keyof typeof LANGUAGES;
export type ForeignLanguage = Exclude<Language, 'PL'>;

export type Order = 'asc' | 'desc';

export interface Sort {
  key: string | null;
  order: Order;
}
export interface HydraQueryOptions {
  page: number;
  sizePerPage: number;
  search?: string;
  sort: Sort;
}
export interface HydraResponse<TData extends {} = {}> {
  'hydra:member': TData[];
  'hydra:totalItems': number;
}
export interface HydraFormattedResponseData<TData extends {} = {}> {
  data: TData[];
  total: number;
}
export interface CreateManyResponse {
  passed: string[];
  rejected: string[];
}
export interface Attachment {
  id: string;
  originalName?: string;
  size?: string;
  contentUrl: string;
  mimeType: string;
}

export interface StringSignature<TData = any> {
  [key: string]: TData;
}

export enum NotificationMessageType {
  EMAIL,
  SMS,
  EMAIL_TEMPLATE,
}
